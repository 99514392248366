import axios from '../../services/axios';

export const ACTIVITY_SAVE_LOADING= 'activity/ACTIVITY_SAVE_LOADING';
export const ACTIVITY_SAVE_SUCCESS= 'activity/ACTIVITY_SAVE_SUCCESS';
export const ACTIVITY_SAVE_ERROR= 'activity/ACTIVITY_SAVE_ERROR';

export const ACTIVITY_GET_LOADING= 'activity/ACTIVITY_GET_LOADING';
export const ACTIVITY_GET_SUCCESS= 'activity/ACTIVITY_GET_SUCCESS';
export const ACTIVITY_GET_ERROR= 'activity/ACTIVITY_GET_ERROR';

export const ACTIVITY_GETTYPE_LOADING= 'activity/ACTIVITY_GETTYPE_LOADING';
export const ACTIVITY_GETTYPE_SUCCESS= 'activity/ACTIVITY_GETTYPE_SUCCESS';
export const ACTIVITY_GETTYPE_ERROR= 'activity/ACTIVITY_GETTYPE_ERROR';

const initialState = {

    isSaveInProgress: false,
    isSaveComplete: false,
    errorSave: '',

    listactivities: {},
    isGetInProgress: false,
    isGetComplete: false,
    errorGet: '',

    activities: {},
    isGetTypeInProgress: false,
    isGetTypeComplete: false,
    errorGetType: '',
};

export function reduxThunkReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIVITY_SAVE_LOADING: {
            return {
                ...state,
                isSaveInProgress: true,
                isSaveComplete: false,
                errorSave: '',
            };
        }
        case ACTIVITY_SAVE_SUCCESS: {
            return {
                ...state,
                isSaveInProgress: false,
                isSaveComplete: true,
                errorSave: '',
            };
        }
        case ACTIVITY_SAVE_ERROR: {
            return {
                ...state,
                isSaveInProgress: false,
                isSaveComplete: false,
                errorSave: action?.errorSave || '',
            };
        }
        case ACTIVITY_GET_LOADING: {
            return {
                ...state,
                isGetInProgress: true,
                isGetComplete: false,
                isSaveInProgress: false,
                isSaveComplete: false,
                listactivities: {},
                errorGet: '',
            };
        }
        case ACTIVITY_GET_SUCCESS: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: true,
                listactivities: action?.listactivities || {},
                errorGet: '',

            };
        }
        case ACTIVITY_GET_ERROR: {
            return {
                ...state,
                isGetInProgress: false,
                isGetComplete: false,
                listactivities: {},
                errorGet: action?.errorGet || '',
            };
        }
        case ACTIVITY_GETTYPE_LOADING: {
            return {
                ...state,
                isGetTypeInProgress: true,
                isGetTypeComplete: false,
                isSaveInProgress: false,
                isSaveComplete: false,
                activities: {},
                errorGetType: '',
            };
        }
        case ACTIVITY_GETTYPE_SUCCESS: {
            return {
                ...state,
                isGetTypeInProgress: false,
                isGetTypeComplete: true,
                activities: action?.activities || {},
                errorGetType: '',

            };
        }
        case ACTIVITY_GETTYPE_ERROR: {
            return {
                ...state,
                isGetTypeInProgress: false,
                isGetTypeComplete: false,
                activities: {},
                errorGetType: action?.errorGet || '',
            };
        }
        default: {
            return {...state};
        }
    }
}
////////////////////////////////////////////////////////////////////
const URL_API = process.env.REACT_APP_API_ENDPOINT;

export const addActivity = (data) => {
    return (dispatch) => {
        dispatch({ 
            type: ACTIVITY_SAVE_LOADING
          });
       
        const obj = {
            id: data?.id || 1,
            libelle: data?.libellé || 'TÉLÉPROSPECTION',
            dateHeure: data?.dateHeure || '2023-03-27T18:13:00.000',
            duree: data?.durée || 120,
            bal: data?.bal || 0,
            pap: data?.pap || 0,
            tel: data?.tel || 0,
            stand: data?.stand || 0,
            foire: data?.foire || 0,

        };
        console.log("PREPARE object to SAVE =======> ", obj);
        /*axios.post(`${URL_API}/todo`, obj).then(
            response => {
              console.log("====> RESPONSE addActivity ====> ", response?.data);
              dispatch({ 
                type: ACTIVITY_SAVE_SUCCESS,
              });
          },
          error => {
            dispatch({ type: ACTIVITY_SAVE_ERROR, errorSave: error?.response?.data });
          });*/
    };
};

export const getType = () => {
    return (dispatch) => {
        dispatch({ 
          type: ACTIVITY_GETTYPE_LOADING
        });

        axios.post(`${URL_API}/listeTypeActivites`,{}).then(
          response => {
            console.log("response listeTypeActivites data API", response?.data);
              dispatch({ 
                type: ACTIVITY_GETTYPE_SUCCESS,
                activities: response?.data || {},
              });
          },
          error => {
            console.log("response listeTypeActivites error API", error?.response?.data);
           dispatch({ type: ACTIVITY_GETTYPE_ERROR, errorGetType: error?.response?.data });
        });
    };
};

export const getActivities = () => {
    return (dispatch) => {
        dispatch({ 
          type: ACTIVITY_GET_LOADING
        });

        axios.post(`${URL_API}/listeActivites`,{filtres: {dateDebut: "20230316" ,dateFin: "" }}).then(
          response => {
            console.log("response listeActivites data API", response?.data);
              dispatch({ 
                type: ACTIVITY_GET_SUCCESS,
                listactivities: response?.data || {},
              });
          },
          error => {
            console.log("response listeActivites error API", error?.response?.data);
           dispatch({ type: ACTIVITY_GET_ERROR, errorGet: error?.response?.data });
        });
    };
};