import React from 'react';
import styled from "styled-components";
import pck from "../../../package.json";
import Form from 'react-bootstrap/Form';

import AlertError from '@Components/alertError';

const HeaderImage= styled.div`
    background-image: url(${process.env.REACT_APP_LOGO_PATH});
    width: 300px;
    height: 73px;
    margin-bottom: 10px;
`;

const ModeEnv = styled.h4`
    color: grey;
    font-size: 10px;
    font-weight: bold;
    margin: 20px 10px 10px 10px;
`;

const VersionText = styled.h3`
    color: grey;
    font-size: 14px;
    font-weight: bold;
    margin: 20px 10px 30px 10px;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
`;

function TestBreak() {
    return (
        <Wrapper>
            <Form>
                <HeaderImage />
                {process.env.REACT_APP_MODE !== 'Mode production' && <ModeEnv>{process.env.REACT_APP_MODE}</ModeEnv>}
                {<VersionText>{pck?.version?.length > 0 ? `Version ${pck?.version}` : ``}</VersionText>}
                <AlertError message={"Le programme de pré-production est en pause !"} show={true}/>
            </Form>
        </Wrapper>
    );
}

export default TestBreak;


