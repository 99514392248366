import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { FaHome } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa";
import { FaBookOpen } from "react-icons/fa";
import { TbMapPins } from "react-icons/tb";
import { BiLogOut } from "react-icons/bi";
import { BiListPlus } from 'react-icons/bi';
import { logout } from '@Store/User';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import pck from "../../../package.json";

import styled from "styled-components";
import colors from 'src/constants/colors';

const NavbarCustom = styled(Navbar)`
    padding: 0px;
`;

const BrandHomkia = styled.div`
    font-size: 14px;
    margin-left: 10px;
`;

const Info = styled.div`
    font-size: 12px;
`;

const IconHome = styled(FaHome)`
    margin-bottom: 5px;
    color: ${colors.TERTIARY};
`;

const IconAddUser = styled(FaUserPlus)`
    margin-bottom: 5px;
    color: ${colors.TERTIARY};
    margin-left: 3px;
    margin-right: 3px;
`;

const IconAddTask = styled(BiListPlus)`
    margin-bottom: 5px;
    color: ${colors.TERTIARY};
    margin-left: 3px;
    margin-right: 3px;
`;

const IconMap = styled(TbMapPins)`
    margin-bottom: 5px;
    color: ${colors.TERTIARY};
    margin-left: 3px;
    margin-right: 3px;
`;

const IconBook = styled(FaBookOpen)`
    margin-bottom: 5px;
    color: ${colors.TERTIARY};
    margin-left: 3px;
    margin-right: 3px;
`;

const IconLogout = styled(BiLogOut)`
    margin-bottom: 5px;
    color: ${colors.TERTIARY};
    margin-left: 3px;
    margin-right: 3px;
`;


function Menu() {
    const dispatch = useDispatch();
    const user = useSelector(state => state?.user);
    const navigate = useNavigate();

    return (
        <>
            {user?.authToken?.length > 0 && 
            <NavbarCustom fixed="bottom" bg="light" variant="light">
                <Navbar.Brand onClick={() => navigate("/home")}>
                    <img
                        alt=""
                        src={process.env.REACT_APP_TINY_LOGO_PATH}
                        width="25"
                        height="25"
                        className="d-inline-block align-top"
                    />{' '}<BrandHomkia><Info>{pck?.version?.length > 0 ? `Version ${pck?.version}` : ``}</Info></BrandHomkia>
                </Navbar.Brand>
                
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link onClick={() => navigate("/home")} style={{alignSelf: 'flex-end'}}>
                            <IconHome />
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/map")} style={{alignSelf: 'flex-end'}}>
                            <IconMap />
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/documentation")} style={{alignSelf: 'flex-end'}}>
                            <IconBook />
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/newMeeting-step01")} style={{alignSelf: 'flex-end'}}>
                            <IconAddUser />
                        </Nav.Link>
                        <Nav.Link onClick={() => navigate("/newActivity")} style={{alignSelf: 'flex-end'}}>
                            <IconAddTask />
                        </Nav.Link>
                        <Nav.Link onClick={() => dispatch(logout())} style={{alignSelf: 'flex-end'}}>
                            <IconLogout />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </NavbarCustom>}
        </>
    );
}  
export default Menu;